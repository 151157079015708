<template>
  <v-app>
    <div
      style="position: fixed; height: 100vh; width: 100vw; background-color: #003677"
    />
    <v-container
      id="fundo"
      class="p-0 fill-height"
      fluid
    >
      <v-row
        class="fill-height"
        align="center"
        justify="center"
      >
        <v-col
          cols="11"
          sm="8"
          md="5"
          lg="4"
          xl="3"
          style="padding: 0px"
        >
          <v-container :style="$vuetify.breakpoint.xs ? 'padding: 4px' : ''">
            <v-container class="pb-4">
              <v-row
                class="layout-logos-login fill-height px-4 height"
                align="center"
                justify="center"
              >
                <v-img
                  class="logo-form-login"
                  src="@/assets/logo_branca.png"
                  height="200px"
                  contain
                />
              </v-row>
            </v-container>
            <v-card
              color="white"
              style="padding: 12px"
            >
              <div style="width: 100%; text-align: center">
                <span
                  style="font-size: 16px; text-align: center; font-weight: 500"
                >Redefinir Senha</span>
              </div>
              <div v-if="!loader">
                <v-form
                  id="form_login"
                  ref="form"
                  v-model="valid"
                  @submit.prevent="send"
                >
                  <v-container class="pa-4">
                    <crud-password stacked-layout />
                    <v-row
                      justify="center"
                      class="pt-2 mt-4"
                    >
                      <v-btn
                        class="text-none text-white"
                        color="success"
                        type="submit"
                      >
                        <span style="font-weight: 600">Redefinir Senha</span>
                      </v-btn>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import rules from '../../utils/formRules'
  export default {
    data () {
      return {
        loader: false,
        valid: null,
        show: false,
        rules,
      }
    },
    computed: {
      ...mapFields('form', ['password', 'passwordConfirm']),
    },
    created () {
      // createFormFields({
      //   create: true,
      //   fields: this.fields,
      //   store: this.$store,
      //   module: 'form',
      // })
      this.api.verifyToken(this.$route.params.token).then(
        result => {
        },
        error => {
          this.Swal.close()
          this.Swal.fire({
            icon: 'error',
            title: 'Token inválido!',
            text: 'Por favor, solicite a alteração de senha novamente.',
            showConfirmButton: true,
          }).then(a => {
            this.$router.push({ path: '/login' })
          })
          console.error(error)
        },
      )
    },
    methods: {
      send () {
        if (this.$refs.form.validate()) {
          this.Swal.fire({
            title: 'Alterando Senha!',
            timerProgressBar: true,
            willOpen: () => {
              this.Swal.showLoading()
            },
            didOpen: () => {
              const form = { ...this.$store.state.form }
              this.api.resetPassword(form, this.$route.params.token).then(
                result => {
                  this.Swal.fire({
                    icon: 'success',
                    title: 'Senha alterada com sucesso!',
                    showConfirmButton: false,
                    timer: 2000,
                  })
                },
                error => {
                  this.Swal.close()
                  this.Swal.fire({
                    icon: 'error',
                    title: 'Ocorreu um erro!',
                    text: 'Por favor solicite a alteração de senha novamente.',
                    showConfirmButton: true,
                  })
                  console.error(error)
                },
              )
            },
          })
          this.$router.push({ path: '/' })
        }
      },
    },
  }
</script>
